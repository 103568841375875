import Calendar, { CalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import './calendar-style.css';

export type ReactCalendarProps = CalendarProps;
export function CustomCalendar({ ...rest }: ReactCalendarProps) {
  const { t } = useTranslation(['common', 'login', 'dashboard']);

  return (
    <div>
      <Calendar locale={t('dashboard:locale')} {...rest} />
    </div>
  );
}
