import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import { LogApp } from '@utils';
import { SimulatorSection } from '@components';
import { selectApp, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { RadioChangeEvent } from 'antd';
import { enumSimulatorBy } from '@configs';
import { memberAPI, settingAPI, simulatorAPI } from '@api';

const POSTransactionSchema = yup.object().shape({
  phone: yup.string().required('Phone is required'),
});

const cashbackSchema = yup.object().shape({
  phone: yup.string().required('Phone is required'),
});

export const SimulatorModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [byType, setbyType] = useState(enumSimulatorBy.BY_TOTAL_AMOUNT);
  const [memberInfo, setMemberInfo] = useState();
  const [getMemberError, setGetMemberError] = useState<string>();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState({
    bill: '',
    balance: '',
    earned: '',
    used: '',
  });
  const { themeMode } = useAppSelector(selectApp);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(POSTransactionSchema),
    defaultValues: useMemo(() => {
      return {
        phone: '',
        type: enumSimulatorBy.BY_TOTAL_AMOUNT,
        totalAmount: '',
        discountCode: '',
        voicherCode: '',
        branchId: '',
        deductedAmount: '',
      };
    }, []),
  });

  const {
    register: cashbackRegister,
    handleSubmit: cashbackHandleSubmit,
    setError: cashbackSetError,
    reset: cashbackReset,
    watch: cashbackWatch,
    getValues: cashbackGetValues,
    formState: { errors: cashbackErrors },
  } = useForm({
    resolver: yupResolver(cashbackSchema),
    defaultValues: useMemo(() => {
      return {
        phone: '',
        increaseAmount: '',
      };
    }, []),
  });
  LogApp(cashbackHandleSubmit, 'cashbackHandleSubmit');
  const handleSubmitPOSTransactionForm = handleSubmit(async (data) => {
    try {
      dispatch(setLoading(true));
      const res = await simulatorAPI.keepOrUse(
        //@ts-ignore
        memberInfo?.member?.id,
        Number(data.totalAmount),
        Number(data.deductedAmount),
      );
      await validatePhone();
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  });

  const handleSubmitCashbackForm = cashbackHandleSubmit(async (data) => {
    try {
      dispatch(setLoading(true));
      const res = await simulatorAPI.bonusCashback(data.phone, Number(data.increaseAmount));
      await validatePhone(cashbackGetValues('phone'));
    } catch (err: any) {
      cashbackSetError('phone', { message: err?.response?.data?.message });
      setMemberInfo(undefined);
    } finally {
      dispatch(setLoading(false));
    }
  });

  const handleChangeBy = ({ target: { value } }: RadioChangeEvent) => {
    setValue('type', value);
  };
  const validatePhone = async (phone = getValues('phone')) => {
    try {
      dispatch(setLoading(true));
      const res = await simulatorAPI.getMemberInfo(phone);
      const data = res.data as any;
      if (res?.success) {
        setMemberInfo(data);
        setGetMemberError(undefined);
      } else {
        setGetMemberError('Member information not found');
      }
    } catch (err: any) {
      setError('phone', { message: err?.response?.data?.message });
      setGetMemberError('Member information not found');
      // setError('phone',{message:""})
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onPreview = async () => {
    if (!getValues('phone')) {
      setError('phone', { message: 'Phone is required' });
      return;
    }
    if (!getValues('totalAmount')) {
      setError('totalAmount', { message: 'Total amount is required' });
      return;
    }
    LogApp(getValues('totalAmount'));
    try {
      dispatch(setLoading(true));
      const res = await simulatorAPI.preview(
        //@ts-ignore
        memberInfo?.member?.id,
        Number(getValues('totalAmount')),
        Number(getValues('deductedAmount')),
      );
      clearErrors();
      setPreviewData({
        balance: res.data.balance,
        bill: res.data.bill,
        earned: res.data.earned,
        used: res.data.used,
      });
      setModalOpened(true);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onClose = () => {
    setModalOpened(false);
  };
  return (
    <SimulatorSection
      register={register}
      watch={watch}
      cashbackRegister={cashbackRegister}
      cashbackWatch={cashbackWatch}
      errors={errors}
      cashbackErrors={cashbackErrors}
      handleSubmit={handleSubmitPOSTransactionForm}
      onPreview={onPreview}
      cashbackHandleSubmit={handleSubmitCashbackForm}
      handleChangeBy={handleChangeBy}
      handleValidatePhone={validatePhone}
      memberInfo={memberInfo}
      getMemberError={getMemberError}
      previewData={previewData}
      modalOpened={modalOpened}
      onClose={onClose}
    />
  );
};
