import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { authAPI } from '@api';
import { LoginForm } from '@components';
import { PATH_FORGOT_PASSWORD, PATH_SIGN_UP, PATH_VERIFY_EMAIL } from '@configs';
import {
  selectApp,
  setLoading,
  setMail,
  setVerifyMailHash,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp, genderMessageFromApi, generateHash } from '@utils';
import i18next from 'i18next';

const schema = yup.object().shape({
  // email: yup.string().required('Email is required'),
  email: yup.string().required(i18next.t('error:email_required')),
});

export const LoginModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { themeMode } = useAppSelector(selectApp);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRedirectToForgot = () => {
    navigate(PATH_FORGOT_PASSWORD);
  };

  const handleRedirectToSignUp = () => {
    navigate(PATH_SIGN_UP);
  };

  const handleLogin = handleSubmit(async (data) => {
    LogApp('Submit Login', data);
    dispatch(setMail(data.email));
    const body = {
      email: data.email,
      hash: generateHash(data.email),
    };

    try {
      dispatch(setLoading(true));
      const res: any = await authAPI.login(body);
      const { data } = res;
      const { hash } = data;
      reset({ email: '' });

      if (res?.success) {
        dispatch(setVerifyMailHash(hash));
        navigate(PATH_VERIFY_EMAIL);
      }
    } catch (err: any) {
      reset({ email: '' });
      toast.error(genderMessageFromApi(err?.code));
      LogApp('API Login', err);
    } finally {
      dispatch(setLoading(false));
    }
  });

  return (
    <LoginForm
      register={register}
      redirectToForgot={handleRedirectToForgot}
      redirectToSignUp={handleRedirectToSignUp}
      errors={errors}
      handleLogin={handleLogin}
    />
  );
};
