import {
  enumNavKey,
  enumRuleType,
  enumCashbackType,
  enumStatus,
  enumGender,
  enumPageSize,
  enumMemberType,
  enumDashboardFilterTime,
} from './enum';
import {
  PATH_ANNOUNCEMENT,
  PATH_BIRTHDAY_SPECIAL,
  PATH_BRANCH_GROUP,
  PATH_CASHBACK_RULES,
  PATH_DASHBOARD,
  PATH_GENERAL,
  PATH_MEMBER,
  PATH_MEMBERSHIP_TIERS,
  PATH_STORES,
  PATH_TERM_AND_POLICY,
  PATH_USER_MANAGEMENT,
  PATH_ABOUT,
} from './../routes/navigation';
import store from 'src/redux/store';
import { SideBarData } from '@interfaces';

export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE || 'frn';
const ALL_THEMES = 'themes';
const CURRENT_THEME = 'theme';

const SIDEBAR_WIDTH = '260px';
const SIDEBAR_COLLAPSED_WIDTH = '105px';
const SIDEBAR_ICON_SIZE = '24px';
const HEADER_HEIGHT = '65px';
const HEADER_PADDING_TOP = '20px';
import i18next from 'i18next';

const SIDEBAR_DATA: SideBarData[] = [
  {
    label: i18next.t('dashboard:user_management'),
    path: PATH_USER_MANAGEMENT,
    pathKey: enumNavKey.USER_MANAGEMENT,
  },
  {
    label: i18next.t('term_and_policy'),
    path: PATH_TERM_AND_POLICY,
    pathKey: enumNavKey.PATH_TERM_AND_POLICY,
  },
  {
    label: i18next.t('about'),
    path: PATH_ABOUT,
    pathKey: enumNavKey.ABOUT,
  },

  // {
  //   label: 'Members',
  //   path: PATH_MEMBER,
  //   pathKey: enumNavKey.MEMBERS,
  // },
  // {
  //   label: 'Branch Group',
  //   path: PATH_BRANCH_GROUP,
  //   pathKey: enumNavKey.BRANCH_GROUP,
  //   subItems: [
  //     { label: 'Group', path: PATH_BRANCH_GROUP, pathKey: enumNavKey.GROUP, subOptions: [] },
  //     { label: 'Stores', path: PATH_STORES, pathKey: enumNavKey.STORES },
  //   ],
  // },
];

const AUTH_THEME_COLOR = '#184f64';

const MAIN_THEME_DATA = {
  mainColor: '#184f64',
};

const MAT_SM_SCREEN_WIDTH = '1279px';
const MAT_SM_SCREEN_WIDTH_MIN = '1280px';

const RESET = 'RESET';

const CASHBACK_RULE_TYPE = [
  {
    value: enumRuleType.standard,
    label: 'Standard Cashback Rule',
  },
  {
    value: enumRuleType.superior,
    label: 'Superior Cashback Rule',
  },
];

const CASHBACK_TYPE = [
  {
    value: enumCashbackType.cost,
    label: '$',
  },
  {
    value: enumCashbackType.percent,
    label: '%',
  },
];

const STATUS_TYPE = [
  {
    value: enumStatus.ACTIVE,
    label: i18next.t('dashboard:user_detail:active'),
  },
  {
    value: enumStatus.INACTIVE,
    label: i18next.t('dashboard:user_detail:inactive'),
  },
  {
    value: enumStatus.NONE,
    label: i18next.t('all'),
  },
];

const GENDER_TYPE = [
  {
    value: enumGender.MALE,
    label: i18next.t('dashboard:user_detail:male'),
  },
  {
    value: enumGender.FEMALE,
    label: i18next.t('dashboard:user_detail:female'),
  },
];
export const TOP_FILTER = [
  {
    value: 10,
    label: 'Top 10',
  },
  {
    value: 20,
    label: 'Top 20',
  },
  {
    value: 50,
    label: 'Top 50',
  },
];

const PAGE_SIZE_OPTIONS = [
  {
    value: enumPageSize.LIMIT_10,
    label: '10',
  },
  {
    value: enumPageSize.LIMIT_20,
    label: '20',
  },
  {
    value: enumPageSize.LIMIT_50,
    label: '50',
  },
];

const FILTER_MEMBER_TYPE = [
  {
    value: enumMemberType.ACTIVE,
    label: 'Active members',
  },
  {
    value: enumMemberType.NEW_MEMBER,
    label: 'New members',
  },
  {
    value: enumMemberType.ALL,
    label: 'All',
  },
];

const DASHBOARD_FILTER_TIME = [
  {
    value: enumDashboardFilterTime.ALL_DAYS,
    label: 'All days',
  },
  {
    value: enumDashboardFilterTime.TODAY,
    label: 'Today',
  },
  {
    value: enumDashboardFilterTime.YESTERDAY,
    label: 'Yesterday',
  },
  {
    value: enumDashboardFilterTime.LAST_7_DAYS,
    label: 'Last 7 days',
  },
  {
    value: enumDashboardFilterTime.LAST_14_DAYS,
    label: 'Last 14 days',
  },
  {
    value: enumDashboardFilterTime.LAST_21_DAYS,
    label: 'Last 21 days',
  },
  {
    value: enumDashboardFilterTime.LAST_28_DAYS,
    label: 'Last 28 days',
  },
  {
    value: enumDashboardFilterTime.LAST_60_DAYS,
    label: 'Last 60 days',
  },
  {
    value: enumDashboardFilterTime.CUSTOM,
    label: 'Custom date',
  },
];

const OPENING_TYPE = {
  ALL: 1,
  CUSTOM: 2,
};
const DAYS = {
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THU: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
  SUN: 'sunday',
};
const DEFAULT_OPENING_HOUR = {
  [DAYS.MON]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.TUE]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.WED]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.THU]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.FRI]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.SAT]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: false,
  },
  [DAYS.SUN]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: false,
  },
};
export const DEFAULT_WELCOMED_BENEFITS_VALUE = {
  CASHBACK: 'cashback',
  POINTS: 'point',
  COUPONS: 'coupon',
  FREE_ITEMS: 'item',
};
const DEFAULT_WELCOMED_BENEFITS = [
  { value: DEFAULT_WELCOMED_BENEFITS_VALUE.CASHBACK, label: 'Cashback', disabled: false },
  { value: DEFAULT_WELCOMED_BENEFITS_VALUE.POINTS, label: 'Points', disabled: false },
  { value: DEFAULT_WELCOMED_BENEFITS_VALUE.COUPONS, label: 'Coupons', disabled: false },
  { value: DEFAULT_WELCOMED_BENEFITS_VALUE.FREE_ITEMS, label: 'Free items', disabled: false },
];
export const ruleDateFormat = 'DD-MM-YYYY';
export const ruleTimeFormat = 'HH:mm:ss';
export const dateOfBirthFormat = 'DD/MM/YYYY';
export const dateTimeFormat = 'HH:mm:ss DD-MM-YYYY';
export const dateFormat = 'DD-MM-YYYY';

export const RESEND_OTP_COUNTDOWN_TIME = 60; //seconds
export const MEMBER_LINK = 'https://minty-member-stage.adamo.tech/';
export const INITIAL_PAGE = 1;
export const DEFAULT_ANNOUNCEMENT_TYPE = 'redirect';
export const DEFAULT_ANNOUNCEMENT_STATUS = true;
export const MAXIMUM_IMAGE_SIZE = 1000000; //bytes - 1 MB
export const MAXIMUM_LIMIT = 1000000; //bytes - 1 MB

export const MAP_BOX_DEV_KEY =
  'pk.eyJ1IjoiYW5keWhpdSIsImEiOiJjbGNyOWl2ZDAwYmdjM3FucGMwbGhkbW90In0.ezl2EXwziuTkjZmYeh4Bcw';
export {
  ALL_THEMES,
  MAIN_THEME_DATA,
  CURRENT_THEME,
  HEADER_HEIGHT,
  SIDEBAR_WIDTH,
  SIDEBAR_DATA,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_ICON_SIZE,
  AUTH_THEME_COLOR,
  HEADER_PADDING_TOP,
  MAT_SM_SCREEN_WIDTH,
  MAT_SM_SCREEN_WIDTH_MIN,
  RESET,
  CASHBACK_TYPE,
  CASHBACK_RULE_TYPE,
  STATUS_TYPE,
  GENDER_TYPE,
  PAGE_SIZE_OPTIONS,
  FILTER_MEMBER_TYPE,
  OPENING_TYPE,
  DEFAULT_OPENING_HOUR,
  DAYS,
  DEFAULT_WELCOMED_BENEFITS,
  DASHBOARD_FILTER_TIME,
};

export const INITIAL_PAGINATION = {
  current: 1,
  total: 10,
  limit: 10,
};

export const USER_STATUS_OPTIONS = [
  {
    label: i18next.t('dashboard:user_detail:active'),
    value: 'active',
  },
  {
    label: i18next.t('dashboard:user_detail:inactive'),
    value: 'inactive',
  },
];

export const USER_GENDER_OPTIONS = [
  {
    label: i18next.t('dashboard:user_detail:male'),
    value: 'male',
  },
  {
    label: i18next.t('dashboard:user_detail:female'),
    value: 'female',
  },
];

export enum StatusUser {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum StaticPageType {
  ABOUT_US = 'about-us',
  TERM_POLICY = 'term-policy',
}

export enum ClientStatusCode {
  OTP_TIMEOUT = 600,
  OTP_INVALID = 601,
  WRONG_OTP_CODE = 602,
  OVERTIME_SCAN_OTP = 603,
  AVAILABILITY_CREATE_SUCCESS = 604,
  AVAILABILITY_CREATE_ERROR = 605,
  AVAILABILITY_CREATE_EXISTED = 606,
  AVAILABILITY_UPDATE_SUCCESS = 607,
  AVAILABILITY_UPDATE_ERROR = 608,
  AVAILABILITY_DELETE_SUCCESS = 609,
  AVAILABILITY_DELETE_ERROR = 610,
  AVAILABILITY_GET_SUCCESS = 611,
  AVAILABILITY_GET_ERROR = 612,
  AVAILABILITY_GET_NOT_FOUND = 613,
  USER_NOT_FOUND = 614,
  EMAIL_ALREADY_EXISTS = 615,
  MOBILE_ALREADY_EXISTS = 616,
  SALESFORCE_CONTACT_ERR = 617,
  SALESFORCE_CONTACT_DUPLICATE = 618,
  USER_UPDATE_SUCCESS = 619,
  USER_NOT_ACTIVE = 620,
  INVALID_LOGIN_LINK = 621,
  ITEM_NOT_FOUND = 622,
  SEND_EMAIL_FAIL = 623,
}
