import { userApi } from '@api';
import { HtmlParser, QuillEditor } from '@components';
import { StaticPageType } from '@configs';
import { setLoading, useAppDispatch } from '@redux';
import { genderMessageFromApi } from '@utils';
import { Button } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export function About() {
  const [open, setOpen] = useState(false);

  const [about, setAbout] = useState('');
  const [temporary, setTemporary] = useState('');
  const { t } = useTranslation(['common']);

  const dispatch = useAppDispatch();

  const getAbout = async () => {
    try {
      dispatch(setLoading(true));
      const res = await userApi.getStaticPage(StaticPageType.ABOUT_US);
      setAbout(res.data.content.replaceAll('\t', '&nbsp;'));
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangePolicy = (value: string) => {
    setTemporary(value);
  };

  const handleEditPolicy = async () => {
    try {
      dispatch(setLoading(true));
      const res = await userApi.editStaticPage({
        content: temporary,
        type: StaticPageType.ABOUT_US,
      });

      setAbout(res.data[0].content.replaceAll('\t', '&nbsp;'));
      setOpen(false);
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    setTemporary(about);
  }, [about]);

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <div className="unreset">
      {!open ? (
        <div style={{ textAlign: 'right', marginBottom: '30px', marginRight: '30px' }}>
          <Button
            onClick={() => setOpen(true)}
            size="large"
            style={{ borderRadius: '8px', minWidth: '100px' }}
            type="primary"
          >
            {t('common:edit')}
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            justifyContent: 'flex-end',
            marginBottom: '30px',
            marginRight: '30px',
          }}
        >
          <Button
            onClick={handleEditPolicy}
            size="large"
            style={{ borderRadius: '8px', minWidth: '100px' }}
            type="primary"
            disabled={temporary === about || !temporary}
          >
            {t('common:save')}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            size="large"
            style={{ borderRadius: '8px', minWidth: '100px' }}
            type="text"
          >
            {t('common:cancel')}
          </Button>
        </div>
      )}
      {open ? (
        <QuillEditor defaultValue={about} onChange={handleChangePolicy} />
      ) : (
        <div>
          {about ? (
            <HtmlParser html={about} />
          ) : (
            <p>{t('common:no_term_and_policy_please_edit_to_add')}</p>
          )}
        </div>
      )}
    </div>
  );
}
