import { Button, Card, Col, Divider, Row, Switch, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ExportOutlined } from '@ant-design/icons';

import { authAPI, userApi } from '@api';
import { CustomCalendar, CustomReactDateRange, SharedButton } from '@components';
import { MAIN_THEME_DATA, StatusUser } from '@configs';
import { selectUser, setLoading, setUserDetail, useAppDispatch, useAppSelector } from '@redux';
import { DayIcon, NightIcon } from '@assets';
import { AvailabilityIem } from './availability-item';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { genderMessageFromApi } from '@utils';
import { toast } from 'react-toastify';

export type AvailabilityModule = {
  createdAt: string;
  date: string;
  day: boolean;
  id: number;
  night: boolean;
  updatedAt: string;
  vacationId: string;
};

export const UserViewDetail = () => {
  const { userDetail } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { userId } = useParams();

  const [availability, setAvailability] = useState<AvailabilityModule[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [checkDate, setCheckDate] = useState<boolean>(true);
  const { t } = useTranslation(['common', 'dashboard']);

  const [loginLink, setLoginLink] = useState<string>('');

  const handleClickGenerateLink = async () => {
    if (userId) {
      const params = {
        userId: Number(userId),
      };
      const res = await authAPI.generateLinkLogin(params);
      const { data } = res;
      setLoginLink(data.link);
    }
  };

  const getAvailability = async () => {
    if (!userId) return;

    try {
      dispatch(setLoading(true));
      const res = await userApi.getAvailability(Number(userId));
      const mapData = res.data.map(({ UserAvailability: _, ...rest }: any) => ({ ...rest }));
      setAvailability(mapData);
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getAvailability();
    if (!userId) {
      dispatch(setUserDetail(null));
      setLoginLink('');
    }
  }, [userId]);

  const handleExportCsv = async () => {
    const bodyCsv = {
      format: 'csv',
      startDate: startDate,
      endDate: endDate,
    };
    if (!startDate || !endDate) {
      setCheckDate(false);
      return;
    }
    try {
      setCheckDate(true);
      dispatch(setLoading(true));
      await userApi.exportCsvFile(bodyCsv, Number(userId));
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleExportClsx = async () => {
    const bodyXlsx = {
      format: 'xlsx',
      startDate: startDate,
      endDate: endDate,
    };
    if (!startDate || !endDate) {
      setCheckDate(false);
      return;
    }
    try {
      setCheckDate(true);
      dispatch(setLoading(true));
      await userApi.exportXlsxFile(bodyXlsx, Number(userId));
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Card>
      <p className="uppercase text-base font-semibold mb-5">
        {t('dashboard:user_detail.user_detail')}
      </p>
      <Row gutter={20}>
        <Col span={6}>
          <div>
            <p className="capitalize text-sm font-medium">{t('dashboard:salesforce_id')}</p>
            <p className="italic capitalize text-sm font-normal">
              {userDetail?.salesforceId || 'N/A'}
            </p>
          </div>
          <div>
            <p className="capitalize  text-sm  font-medium">{t('dashboard:full_name')}</p>
            <p className="italic capitalize text-sm  font-normal">
              {!userDetail?.firstName || !userDetail.lastName
                ? 'N/A'
                : `${userDetail.firstName} ${userDetail.lastName}`}
            </p>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <p className="capitalize  text-sm  font-medium">{t('common:email')}</p>
            <p className="italic text-sm  font-normal">{userDetail?.email || 'N/A'}</p>
          </div>
          <div>
            <p className="capitalize  text-sm  font-medium">{t('dashboard:user_detail.phone')}</p>
            <p className="italic capitalize text-sm font-normal">{userDetail?.mobile || 'N/A'}</p>
          </div>
        </Col>
        {userDetail?.status === StatusUser.ACTIVE && (
          <Col span={6}>
            <div>
              <p className="capitalize text-sm font-medium">
                {t('dashboard:user_detail.login_link')}
              </p>
              {loginLink && (
                <div style={{ maxWidth: '500px' }}>
                  <a
                    target="_blank"
                    href={loginLink}
                    className="break-words underline text-primary "
                  >
                    {loginLink}
                  </a>
                </div>
              )}

              <div className="w-1/5 mt-3">
                <Button
                  type="primary"
                  style={{ borderRadius: '6px' }}
                  onClick={handleClickGenerateLink}
                >
                  {t('dashboard:user_detail.generate_link')}
                </Button>
              </div>
            </div>
          </Col>
        )}
        <Col span={6}>
          {/* <div>
            <p className="capitalize  text-sm  font-medium">{t('dashboard:gender')}</p>
            <p className="italic capitalize text-sm font-normal">
              {userDetail?.gender
                ? userDetail.gender === 'male'
                  ? t('dashboard:user_detail.male')
                  : t('dashboard:user_detail.female')
                : 'N/A'}
            </p>
          </div> */}
          <div className="mb-3">
            <p className="capitalize text-sm font-medium">{t('dashboard:status')}</p>
            <Tag
              className='italic capitalize text-sm font-normal"'
              color={userDetail?.status === 'active' ? 'green' : 'red'}
            >
              {userDetail?.status
                ? userDetail.status === 'active'
                  ? t('dashboard:user_detail.active')
                  : t('dashboard:user_detail.inactive')
                : 'N/A'}
            </Tag>
          </div>
        </Col>
      </Row>
      <Divider />
      <p className="capitalize  text-sm  font-medium">
        {t('dashboard:user_detail.export_availability')}
      </p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '61px',
          justifyContent: 'flex-start',
          marginBottom: '30px',
          marginTop: '30px',
        }}
      >
        <div>
          <CustomReactDateRange
            triggerButton={(open, setOpen) => (
              <Button
                type={open ? 'primary' : 'default'}
                style={{ borderRadius: '6px' }}
                onClick={() => setOpen(!open)}
              >
                {t('dashboard:user_detail.select_date_range')}
              </Button>
            )}
            onChangeRage={(value) => {
              const startDate: any = value?.startDate;
              const endDate: any = value?.endDate;
              setCheckDate(startDate || endDate ? true : false);
              setStartDate(format(startDate, 'yyyy-MM-dd'));
              setEndDate(format(endDate, 'yyyy-MM-dd'));
            }}
          />
        </div>
        <Button style={{ borderRadius: '6px' }} onClick={handleExportCsv} icon={<ExportOutlined />}>
          {t('dashboard:user_detail.export_csv')}
        </Button>
        <Button
          style={{ borderRadius: '6px' }}
          onClick={handleExportClsx}
          icon={<ExportOutlined />}
        >
          {t('dashboard:user_detail.export_xlsx')}
        </Button>
      </div>
      {!checkDate ? (
        <p className="text-sm  font-medium" style={{ color: 'red' }}>
          {t(
            'dashboard:user_detail.please_pick_your_start_date_and_end_date_before_exporting_file',
          )}
        </p>
      ) : null}
      <AvailabilityIem daysData={availability} />
    </Card>
  );
};
