import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { userApi } from '@api';
import { FormUser } from '@components';
import { PATH_USER_MANAGEMENT, USER_GENDER_OPTIONS, USER_STATUS_OPTIONS } from '@configs';
import { selectUser, setLoading, setUserDetail, useAppDispatch, useAppSelector } from '@redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { genderMessageFromApi } from '@utils';
import i18next from 'i18next';

export const UserForm = () => {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userDetail } = useAppSelector(selectUser);

  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [selectedGender, setSelectedGender] = useState<string>();
  const { t } = useTranslation(['common', 'dashboard', 'error']);
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(i18next.t('error:email_required'))
      .email(i18next.t('error:email_invalid')),
    // saleForceId: yup.string().required('Sale force ID is required'),
    firstName: yup.string().required(i18next.t('error:first_name_required')),
    lastName: yup.string().required(i18next.t('error:last_name_required')),
    // gender: yup.string().required(i18next.t('error:gender_required')),
    mobile: yup.string().required(i18next.t('error:phone_required')),
    gender: yup.string(),
    sale_force_id: yup.string().required(i18next.t('error:field_required')),
    status: yup.string(),
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      mobile: '',
      sale_force_id: '',
      status: '',
      // gender: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!userId) {
      dispatch(setUserDetail(null));
    } else {
      reset({
        email: userDetail?.email || '',
        firstName: userDetail?.firstName || '',
        lastName: userDetail?.lastName || '',
        //gender: userDetail?.gender || '',
        mobile: userDetail?.mobile || '',
        sale_force_id: userDetail?.salesforceId || '',
        status: userDetail?.status || '',
      });
    }
  }, [userId, reset, dispatch, userId, userDetail]);

  const handleClickAction = handleSubmit(async ({ sale_force_id, ...rest }) => {
    const newData = {
      ...rest,
      salesforceId: sale_force_id,
    };
    try {
      dispatch(setLoading(true));
      if (userId) {
        const res: any = await userApi.updateUser(newData, Number(userId));
        if (res?.success) {
          toast.success(t('error:user_update_success'));
          navigate(PATH_USER_MANAGEMENT);
        }
      } else {
        const res: any = await userApi.addNewUser(newData);
        if (res?.success) {
          toast.success(t('error:user_update_success'));
          navigate(PATH_USER_MANAGEMENT);
        }
      }
    } catch (error: any) {
      if (error) {
        toast.error(genderMessageFromApi(error?.code));
      }
    } finally {
      dispatch(setLoading(false));
    }
  });

  const handleClickCancel = () => {
    navigate(PATH_USER_MANAGEMENT);
  };

  const handleSelectValue = (value: string) => {
    setSelectedStatus(value);
  };

  const handleSelectGender = (value: string) => {
    setSelectedGender(value);
  };

  return (
    <FormUser
      formTitle={userId ? t('dashboard:user_detail.edit_user_form') : t('dashboard:add_new_user')}
      onClickAction={handleClickAction}
      onClickCancel={handleClickCancel}
      buttonLabel={userId ? t('common:save') : t('common:create')}
      register={register}
      errors={errors}
      statusSelectedOptions={USER_STATUS_OPTIONS}
      onSelectedStatusValue={handleSelectValue}
      onChangePhoneNumber={(phone) => setPhoneNumber(phone)}
      phoneNumber={phoneNumber || userDetail?.mobile}
      setValue={setValue}
      getValues={getValues}
      control={control}
      selectedStatusValue={userDetail?.status || selectedStatus}
      genderSelectedOptions={USER_GENDER_OPTIONS}
      selectedGenderValue={userDetail?.gender || selectedGender}
      onSelectedGenderValue={handleSelectGender}
      userDetailData={userDetail}
    />
  );
};
