import React from 'react';
import { Card } from 'antd';
import { SharedButton } from '../shared';
import { MAIN_THEME_DATA } from '@configs';
import { themes } from '@theme';
import { useTranslation } from 'react-i18next';

interface FormCardProps {
  children: React.ReactNode;
  title?: string;
  onClickAction: () => void;
  onClickCancel: () => void;
  buttonLabel?: string;
}

export const FormCard: React.FC<FormCardProps> = ({
  children,
  title,
  onClickAction,
  onClickCancel,
  buttonLabel,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Card>
      <p className="uppercase text-base font-semibold mb-3">{title}</p>

      {children}

      <div className="flex justify-center items-center mt-5">
        <div className="w-1/5">
          <SharedButton
            text={t('common:cancel')}
            btnStyle="basic"
            textColor="#fff"
            backgroundColor={themes.theme.light.colors.subText}
            onClick={onClickCancel}
          />
        </div>
        <div className="w-1/5 ml-5">
          <SharedButton
            text={buttonLabel}
            btnStyle="basic"
            textColor="#fff"
            backgroundColor={MAIN_THEME_DATA.mainColor}
            onClick={onClickAction}
          />
        </div>
      </div>
    </Card>
  );
};
