import common from './common.json';
import login from './login.json';
import dashboard from './dashboard.json';
import error from './error.json';
export const en = {
  common,
  login,
  dashboard,
  error,
};
