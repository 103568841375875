import { BaseIconProps } from './day-icon';

export const NightIcon = ({ height = 32, width = 32 }: BaseIconProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.752 7.312a.864.864 0 0 1 .09.966 8.108 8.108 0 0 0-.988 3.892c0 4.524 3.688 8.187 8.233 8.187.593 0 1.17-.062 1.725-.18a.885.885 0 0 1 .91.355.824.824 0 0 1-.034 1.005A9.395 9.395 0 0 1 16.389 25c-5.186 0-9.387-4.178-9.387-9.326 0-3.875 2.378-7.198 5.765-8.607a.846.846 0 0 1 .985.245Z"
      fill="#6AA2FF"
    />
    <path
      d="M19.145 10.541a.244.244 0 0 1 .464 0l.435 1.307c.195.583.651 1.04 1.234 1.235l1.307.435a.244.244 0 0 1 0 .463l-1.307.436a1.95 1.95 0 0 0-1.234 1.234l-.435 1.307a.244.244 0 0 1-.464 0l-.435-1.307a1.95 1.95 0 0 0-1.234-1.234l-1.308-.435a.243.243 0 0 1 0-.464l1.308-.435a1.952 1.952 0 0 0 1.234-1.235l.435-1.307Zm3.453-3.43a.163.163 0 0 1 .308 0l.29.87c.13.39.434.695.824.824l.87.29a.164.164 0 0 1 0 .309l-.87.29a1.3 1.3 0 0 0-.824.824l-.29.87a.163.163 0 0 1-.308 0l-.29-.87a1.3 1.3 0 0 0-.824-.824l-.87-.29a.163.163 0 0 1 0-.308l.87-.29a1.3 1.3 0 0 0 .823-.824l.29-.87Z"
      fill="#6AA2FF"
    />
  </svg>
);
