import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { authAPI } from '@api';
import { VerifyEmailForm } from '@components';
import { enumStatusCode, PATH_LOGIN, PATH_USER_MANAGEMENT } from '@configs';
import {
  selectAuth,
  setAccessToken,
  setLoading,
  setVerifyMailHash,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { genderMessageFromApi, generateHash, LogApp } from '@utils';
import i18next from 'i18next';

const schema = yup.object().shape({
  otp: yup
    .string()
    .required(i18next.t('error:otp_required'))
    .max(6, i18next.t('error:otp_character'))
    .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, {
      message: i18next.t('error:otp_invalid'),
    }),
});

export const VerifyEmailModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { verifyMailHash, mail } = useAppSelector(selectAuth);

  const [resendOTPCountdown, setResendOTPCountdown] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleBackToLogin = () => {
    navigate(PATH_LOGIN);
  };

  const handleVerifyEmail = handleSubmit(async (value) => {
    LogApp('Submit verify', value);
    if (verifyMailHash) {
      const body = {
        otp: value.otp,
        hash: verifyMailHash || '',
      };
      try {
        dispatch(setLoading(true));
        const res: any = await authAPI.verifyEmail(body);
        dispatch(setAccessToken(res?.accessToken));
        navigate(PATH_USER_MANAGEMENT);
      } catch (err: any) {
        // reset({ otp: '' });
        toast.error(genderMessageFromApi(err?.code));
        LogApp('ERROR API Verify Email', err?.response);
        const error = err?.response?.data;
        // setError('otp', { type: 'custom', message: error?.errors });
      } finally {
        dispatch(setLoading(false));
      }
    }
  });

  // const genForgotEmailHash = (email: string) => {
  //   const secretKey = process.env.REACT_APP_SEND_FORGOT_EMAIL_KEY;
  //   const plainStr = email + secretKey + moment().format('DD/MM/YYYY');
  //   const checkHash = md5(plainStr);
  //   return checkHash;
  // };

  const handleResendEmail = async () => {
    const body = {
      email: mail,
      hash: generateHash(mail),
    };

    try {
      dispatch(setLoading(true));
      const res: any = await authAPI.login(body);
      const { data } = res;
      const { hash } = data;
      dispatch(setVerifyMailHash(hash));
      dispatch(setLoading(false));
    } catch (err: any) {
      const error = err?.response?.data;
      toast.error(genderMessageFromApi(err?.code));
      setError('otp', { type: 'custom', message: error?.errors });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onEndResendOTPCountdown = () => {
    setResendOTPCountdown(false);
  };

  return (
    <VerifyEmailForm
      register={register}
      onBack={handleBackToLogin}
      errors={errors}
      handleVerifyEmail={handleVerifyEmail}
      handleResendEmail={handleResendEmail}
      onEndResendOTPCountdown={onEndResendOTPCountdown}
    />
  );
};
