import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { settingAPI } from '@api';
import { UserProfileSection } from '@components';
import { IGetMerchantInfo, IMerchantInfo } from '@interfaces';
import { selectApp, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { LogApp, genderMessageFromApi } from '@utils';
import * as yup from 'yup';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const schema = yup.object().shape({
  full_name: yup.string().required(i18next.t('error:full_name_required')),
});
const passSchema = yup.object({
  password: yup.string().required(i18next.t('error:pass_required')),
  new_password: yup.string().required(i18next.t('error:new_pass_required')),
  conf_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], i18next.t('error:cf_not_match'))
    .required(i18next.t('error:cf_pass_required')),
});
export const UserProfileModule = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { themeMode } = useAppSelector(selectApp);
  const { t } = useTranslation(['common', 'dashboard', 'error']);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return {
        email: '',
        full_name: '',
        receive_noti: false,
      };
    }, []),
  });
  const {
    register: registerPass,
    handleSubmit: handleSubmitPass,
    reset: resetPass,
    formState: { errors: errorsPass },
  } = useForm<FieldValues>({
    resolver: yupResolver(passSchema),
    defaultValues: useMemo(() => {
      return {
        password: '',
        new_password: '',
        conf_password: '',
      };
    }, []),
  });
  const getUserInfo = async () => {
    try {
      dispatch(setLoading(true));
      const res: IGetMerchantInfo = await settingAPI.getUserInfo();
      const merchantInfo = res.data as IMerchantInfo;
      reset({
        email: merchantInfo?.email || '',
        full_name: merchantInfo?.full_name || '',
        receive_noti: merchantInfo?.receive_noti,
      });
    } catch (error: any) {
      toast.error(`${error?.response?.data?.message}`, {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
      });
      LogApp(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateUserInfo = handleSubmit(async (data) => {
    try {
      dispatch(setLoading(true));
      const res = await settingAPI.updateUserInfo({
        full_name: data.full_name,
        receive_noti: data.receive_noti,
      });
      toast.success(t('error:edit_successfully'), {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: themeMode,
      });
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
    } finally {
      dispatch(setLoading(false));
    }
  });
  const changePassword = handleSubmitPass(async (data) => {
    try {
      dispatch(setLoading(true));
      const res = await settingAPI.changePassword({
        password: data.password,
        newPassword: data.new_password,
      });
      toast.success(t('error:edit_successfully'), {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: themeMode,
      });
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
      LogApp(error, 'ndherr');
      LogApp(error?.response, 'ndherr1');
    } finally {
      dispatch(setLoading(false));
    }
  });
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <UserProfileSection
      errorsPass={errorsPass}
      registerPass={registerPass}
      errors={errors}
      onEditProfile={updateUserInfo}
      register={register}
      watch={watch}
      setValue={setValue}
      onSubmitChangePass={changePassword}
    />
  );
};
