import i18next from 'i18next';

export const columns = [
  {
    title: i18next.t('dashboard:no'),
    dataIndex: 'no',
    key: 'no',
  },
  {
    title: i18next.t('dashboard:salesforce_id'),
    dataIndex: 'salesforceId',
    key: 'salesforceId',
  },
  {
    title: i18next.t('dashboard:full_name'),
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: i18next.t('dashboard:email'),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: i18next.t('dashboard:mobile'),
    dataIndex: 'mobile',
    key: 'mobile',
  },
  // {
  //   title: i18next.t('dashboard:gender'),
  //   dataIndex: 'gender',
  //   key: 'gender',
  // },
  {
    title: i18next.t('dashboard:status'),
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: i18next.t('dashboard:last_update_time'),
    dataIndex: 'lastUpdate',
    key: 'lastUpdate',
  },
  {
    title: i18next.t('dashboard:action'),
    dataIndex: 'actions',
    key: 'actions',
    render: (_: any, record: any) => {
      return record.actions.map((item: any) => {
        return item === 'edit' ? i18next.t('edit') : i18next.t('dashboard:user_detail:inactive');
      });
    },
  },
];
