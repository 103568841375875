import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form';

import { AppModal, SharedButton, SharedCheckbox, ShareInput, ShareSelectInput } from '@components';
import { ILoginFields } from '@interfaces';
import { AUTH_THEME_COLOR, enumSimulatorBy } from '@configs';
import styled from 'styled-components';
import { Radio, RadioChangeEvent, Tabs } from 'antd';
import { LogApp } from '@utils';

interface IProps {
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  errors: Partial<FieldErrorsImpl<any>>;
  cashbackRegister: UseFormRegister<any>;
  cashbackWatch: UseFormWatch<any>;
  cashbackErrors: Partial<FieldErrorsImpl<any>>;
  byType?: enumSimulatorBy;
  handleSubmit: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  onPreview: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  cashbackHandleSubmit: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  handleChangeBy?: (value: RadioChangeEvent) => void;
  handleValidatePhone: (phone: string) => void;
  getMemberError?: string;
  memberInfo?: any;
  previewData?: any;
  modalOpened: boolean;
  onClose: () => void;
}

export const SimulatorSection = memo((props: IProps) => {
  const {
    register,
    watch,
    errors,
    cashbackRegister,
    cashbackErrors,
    handleSubmit,
    cashbackHandleSubmit,
    handleChangeBy,
    handleValidatePhone,
    getMemberError,
    memberInfo,
    onPreview,
    previewData,
    modalOpened,
    onClose,
  } = props;
  return (
    <StyledSimulatorSection className="sm-section">
      <h2 className="sim-title">SIMULATOR</h2>
      <div className="main-content">
        <div className="left-content">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="POS Transaction" key="1">
              {/* @ts-ignore */}
              <form onSubmit={handleSubmit} className="sim-form">
                <div className="form-field">
                  <div className="label">
                    <p className="label-text">Phone Number</p>
                  </div>
                  <div className="w_full">
                    <div className="input-contain--right flex w_full items-center mb-4">
                      <ShareInput
                        placeholder="Phone"
                        name="phone"
                        className="input hidden-arrow-num-input"
                        type="number"
                        errors={errors['phone']?.message}
                        register={register}
                      />
                      <SharedButton
                        onClick={() => handleValidatePhone(watch('phone'))}
                        text="Validate"
                        className="sub-btn validate-btn ml-3"
                        backgroundColor={AUTH_THEME_COLOR}
                        btnStyle="pad"
                      />
                    </div>
                    {/* <div className="sim-byType">
                      <Radio.Group value={watch('type')} onChange={handleChangeBy}>
                        <SharedCheckbox
                          className="filter-options__cb"
                          size={'2rem'}
                          value={enumSimulatorBy.BY_TOTAL_AMOUNT}
                          type="radio"
                          text="By Total Amount"
                        />
                        <SharedCheckbox
                          className="filter-options__cb"
                          size={'2rem'}
                          value={enumSimulatorBy.BY_PRODUCT}
                          type="radio"
                          text="By Product"
                        />
                      </Radio.Group>
                    </div> */}
                  </div>
                </div>
                <div className="form-field">
                  <div className="label">
                    <p className="label-text">Total Amount</p>
                  </div>
                  <ShareInput
                    placeholder="Total amount"
                    name="totalAmount"
                    className="input"
                    type="number"
                    step={0.1}
                    errors={errors['totalAmount']?.message}
                    register={register}
                    inputDefaultStyle="preTab"
                    prefix="$"
                  />
                </div>
                {/* <div className="form-field">
                  <div className="label">
                    <p className="label-text">Discount Code</p>
                  </div>
                  <ShareInput
                    placeholder="Discount"
                    name="discountCode"
                    className="input"
                    errors={errors['discountCode']?.message}
                    register={register}
                  />
                </div>
                <div className="form-field">
                  <div className="label">
                    <p className="label-text">Voucher Code</p>
                  </div>
                  <ShareInput
                    placeholder="Voicher"
                    name="voicherCode"
                    className="input"
                    errors={errors['voicherCode']?.message}
                    register={register}
                  />
                </div> */}
                {/* <div className="form-field">
                  <div className="label">
                    <p className="label-text">Branch Id</p>
                  </div>
                  <ShareSelectInput
                    data={[]}
                    onChange={(value: any) => null}
                    className="deducted-select"
                    containerClassName="select-filter flex items-center mb-4 w_full"
                    // defaultValue={tierFilterData ? tierFilterData?.find((i: any) => i?.value === payload?.tier_id)?.label : undefined}
                    defaultValue={undefined}
                    key={`${0}-tierM`}
                  />
                </div> */}
                <div className="form-field">
                  <div className="label">
                    <p className="label-text">Deducted Amount</p>
                  </div>
                  <ShareInput
                    placeholder="Deducted Amount"
                    name="deductedAmount"
                    className="input"
                    errors={errors['deductedAmount']?.message}
                    register={register}
                    inputDefaultStyle="preTab"
                    prefix="$"
                  />
                </div>
                <div className="actions flex items-center">
                  <SharedButton
                    disabled={!!getMemberError}
                    typeHtml="submit"
                    text="Run"
                    className="submit__btn run-btn"
                    backgroundColor={AUTH_THEME_COLOR}
                    btnStyle="pad"
                  />
                  <SharedButton
                    disabled={!!getMemberError}
                    typeHtml="button"
                    text="Preview"
                    className="submit__btn preview-btn ml-3"
                    backgroundColor={AUTH_THEME_COLOR}
                    btnStyle="pad"
                    onClick={onPreview}
                  />
                </div>
              </form>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Cashback" key="2">
              {/* @ts-ignore */}
              <form onSubmit={cashbackHandleSubmit} className="sim-form">
                <div className="form-field">
                  <div className="label">
                    <p className="label-text">Phone Number</p>
                  </div>
                  <ShareInput
                    placeholder="Phone"
                    name="phone"
                    className="input"
                    errors={cashbackErrors['phone']?.message}
                    register={cashbackRegister}
                  />
                </div>
                <div className="form-field">
                  <div className="label">
                    <p className="label-text">Increase Amount</p>
                  </div>
                  <ShareInput
                    placeholder="Amount"
                    name="increaseAmount"
                    className="input"
                    type="number"
                    step={0.1}
                    errors={cashbackErrors['increaseAmount']?.message}
                    register={cashbackRegister}
                  />
                </div>
                <div className="actions">
                  <SharedButton
                    typeHtml="submit"
                    text="Submit"
                    className="submit__btn uk-btn"
                    backgroundColor={AUTH_THEME_COLOR}
                    btnStyle="pad"
                  />
                </div>
              </form>
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="right-content">
          {(getMemberError || memberInfo) && (
            <>
              <h3 className="result-title">RESULT</h3>
              {!!getMemberError && <p className="error-txt">{getMemberError}</p>}
              {!!memberInfo && !getMemberError && (
                <>
                  <div className="info">
                    <div className="items flex items-center mb-2">
                      <span className="label">Balance:</span>
                      <span>${memberInfo?.cashback}</span>
                    </div>
                    <div className="items flex items-center mb-2">
                      <span className="label">Name:</span>
                      <span>{memberInfo?.member?.name}</span>
                    </div>
                    {/* <div className="items flex items-center mb-2">
                      <span className="label">Id:</span>
                      <span>20203123</span>
                    </div> */}
                    <div className="items flex items-center mb-2">
                      <span className="label">Tier:</span>
                      <span>{memberInfo?.tier?.name}</span>
                    </div>
                    <div className="items flex items-center mb-2">
                      <span className="label">Email:</span>
                      <span>{memberInfo?.member?.email}</span>
                    </div>
                    <div className="items flex items-center mb-2">
                      <span className="label">Adress:</span>
                      <span>{memberInfo?.member?.address}</span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {modalOpened && (
        <AppModal onClose={onClose} open>
          <div className="modal-inner">
            <h2>Transaction preview</h2>
            <div>
              <div className="transaction-item">
                <h3>Total amount</h3>
                <h3>${previewData?.bill}</h3>
              </div>
              <div className="transaction-item">
                <h3>Deducted amount</h3>
                <h3>${previewData?.used}</h3>
              </div>
              <div className="transaction-item">
                <h3>Cash back</h3>
                <h3>${previewData?.earned}</h3>
              </div>
            </div>
            <div className="actions flex items-center justify-center mt-3">
              <SharedButton
                typeHtml="button"
                text="Cancel"
                className="cancel-btn ml-3"
                backgroundColor={AUTH_THEME_COLOR}
                btnStyle="pad"
                onClick={onClose}
              />
            </div>
          </div>
        </AppModal>
      )}
    </StyledSimulatorSection>
  );
});

const StyledSimulatorSection = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .sim-title {
    text-align: center;
    font-weight: 700;
    font-size: 2.1rem;
    margin-bottom: 2rem;
    color: #184f64;
  }
  .main-content {
    display: flex;
    align-items: center;
    height: 100%;
    .left-content {
      flex: 3;
      height: 100%;
      padding: 0.6rem 5.2rem;
    }
    .right-content {
      flex: 2;
      height: 100%;
      border-left: 1px solid #c8cdcf;
      padding: 2.6rem 5.2rem;
      .error-txt {
        text-align: center;
      }
      p {
        text-align: center;
      }
      .result-title {
        text-align: center;
        font-weight: 600;
        font-size: 1.8rem;
        margin-bottom: 2rem;
        color: #123746;
      }
      .info {
        padding: 1.2rem 9rem;
        .items .label {
          width: 20rem;
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
    }
  }

  .sim-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.5rem 0;
    .form-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.6rem;
      .label {
        margin-right: 2.3rem;
        .label-text {
          font-size: 1.6rem;
          font-weight: 500;
          margin-bottom: 0.6rem;
          color: ${({ theme }: any) => theme?.colors?.text};
        }
        .label-desc {
          font-size: 1.3rem;
          font-weight: 400;
          color: ${({ theme }: any) => theme?.colors?.secondary};
        }
      }
      @media (min-width: 1280px) {
        flex-direction: row;
        .label {
          width: 40%;
        }
      }
    }
    .shared-input {
      margin-bottom: 0.8rem;
    }
    .inner-input,
    .ant-picker,
    input,
    .ant-select-selector {
      @media (min-width: 768px) {
        height: 4rem;
      }
    }
  }

  .input-contain--right {
    .shared-input {
      margin-bottom: 0;
    }
    .sub-btn {
      /* height: 2rem; */
      padding: 0.5rem 0;
      max-width: 13rem;
    }
  }

  .sim-byType {
    .filter-options__cb {
      margin-bottom: 0.8rem;
    }
  }
  .modal-inner {
    width: 100rem;
    h2 {
      text-align: center;
    }
    .transaction-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 20rem;
      margin-top: 1rem;
    }
    .cancel-btn {
      height: 4.5rem;
      width: fit-content;
    }
  }
`;
