import { ILoginFields, IVerifyEmailFields } from '@interfaces';
import axiosClient from './axiosClient';

const url = 'api/users';
const authUrl = '/api/auth/generate-link';

export const authAPI = {
  login: (values: ILoginFields) => {
    return axiosClient.post(`${url}/send-otp-admin`, values);
  },
  verifyEmail: (values?: IVerifyEmailFields) => {
    return axiosClient.post(`${url}/login-admin`, values);
  },
  generateLinkLogin: (body: any) => {
    return axiosClient.post(authUrl, body);
  },
};
