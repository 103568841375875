import React, { useState } from 'react';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  Controller,
} from 'react-hook-form';
import { FormCard } from '../FormCard';
import { Row, Col } from 'antd';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import type { UseFormGetValues, Control } from 'react-hook-form';

import { ShareInput, ShareSelectInput } from '../shared';
import { UserItem } from '@interfaces';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface EditUserProps {
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
  sale_force_id: string;
  status: string;
  //gender: string;
}
interface FormUserProps {
  onClickAction: (data?: any) => void;
  onClickCancel: () => void;
  buttonLabel?: string;
  formTitle: string;
  register: UseFormRegister<FieldValues | any>;
  errors: Partial<FieldErrorsImpl<any>>;
  statusSelectedOptions: any[];
  onSelectedStatusValue: (value: any) => void;
  onChangePhoneNumber: (phone: string) => void;
  phoneNumber?: string;
  setValue: UseFormSetValue<EditUserProps>;
  control?: Control<EditUserProps>;
  selectedStatusValue?: string;
  selectedGenderValue?: string;
  genderSelectedOptions: any[];
  getValues?: UseFormGetValues<EditUserProps>;
  onSelectedGenderValue: (value: string) => void;
  userDetailData: UserItem | null;
}

export const FormUser: React.FC<FormUserProps> = ({
  onClickAction,
  onClickCancel,
  buttonLabel,
  formTitle,
  register,
  errors,
  statusSelectedOptions,
  onSelectedStatusValue,
  onChangePhoneNumber,
  phoneNumber,
  control,
  getValues,
  selectedStatusValue,
  selectedGenderValue,
  setValue,
  genderSelectedOptions,
  onSelectedGenderValue,
  userDetailData,
}) => {
  const location = useLocation();
  const isDisable = location.pathname.startsWith('/user-management/edit-user/');

  const { t } = useTranslation(['common', 'dashboard']);

  return (
    <FormCard
      title={formTitle}
      onClickAction={onClickAction}
      buttonLabel={buttonLabel}
      onClickCancel={onClickCancel}
    >
      <Row gutter={24} justify="space-between">
        <Col span={12}>
          <div className="mb-4">
            <Controller
              name="sale_force_id"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <ShareInput
                    label={t('dashboard:salesforce_id')}
                    placeholder="Sale force ID"
                    name="salesforceId"
                    className="input"
                    value={value}
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.value);
                    }}
                    errors={error?.message}
                    disabled={isDisable}
                  />
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="email"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <ShareInput
                    label={t('common:email')}
                    placeholder={t('common:email')}
                    name="email"
                    className="input"
                    type="email"
                    value={value.toLowerCase()}
                    errors={error?.message}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.value);
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="firstName"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <ShareInput
                    label={t('dashboard:user_detail.first_name')}
                    placeholder={t('dashboard:user_detail.first_name')}
                    name="firstName"
                    className="input"
                    type="text"
                    value={value}
                    errors={error?.message}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.value);
                    }}
                  />
                );
              }}
            />
          </div>
          {/* <div className="mb-4"> */}
          {/* <ShareInput
              label="Gender"
              placeholder="Gender"
              name="gender"
              className="input"
              type="text"
              errors={errors['gender']?.message}
              register={register}
            /> */}

          {/* <Controller
              name="gender"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <ShareSelectInput
                    data={genderSelectedOptions}
                    onChange={onChange}
                    label={t('dashboard:gender')}
                    value={value}
                    errors={error?.message}
                  />
                );
              }}
            />
          </div> */}
        </Col>
        <Col span={12}>
          <div className="mb-4">
            {/* <ShareInput
              label="Mobile Phone"
              placeholder="Mobile Phone"
              name="phone"
              className="input"
              type="text"
              errors={errors['phone']?.message}
              register={register}
            /> */}
            <p className="pb-[8px] m-0">{t('dashboard:user_detail.phone_number')}</p>
            <Controller
              name="mobile"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <>
                    <PhoneInput
                      country={'fr'}
                      value={value}
                      inputStyle={{
                        height: '38px',
                        width: '100%',
                      }}
                      onChange={(phone: string, data: CountryData) => {
                        if (phone.startsWith(data.dialCode)) {
                          const subPhone = phone.substring(data.dialCode.length);
                          if (subPhone.startsWith('0')) {
                            const formatPhone = subPhone.substring(1);
                            onChange(`+${data.dialCode}${formatPhone}`, data.dialCode);
                          } else {
                            onChange(`+${phone}`, data.dialCode);
                          }
                        } else {
                          const mapPhone = phone.substring(1);
                          onChange(`+${data.dialCode}${mapPhone}`, data.dialCode);
                        }
                      }}
                    />
                    {error && <p className="text-[#ff0000] text-xs mt-3">{error.message}</p>}
                  </>
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="status"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <ShareSelectInput
                    placeholder={t('dashboard:status')}
                    data={statusSelectedOptions}
                    onChange={onChange}
                    label={t('dashboard:status')}
                    errors={error?.message}
                    value={value}
                  />
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="lastName"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <ShareInput
                    label={t('dashboard:user_detail.last_name')}
                    placeholder={t('dashboard:user_detail.last_name')}
                    name="lastName"
                    className="input"
                    type="text"
                    value={value}
                    errors={error?.message}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.value);
                    }}
                  />
                );
              }}
            />
          </div>
        </Col>
      </Row>
    </FormCard>
  );
};
