import { ILoginFields, IVerifyEmailFields } from '@interfaces';
import axiosClient from './axiosClient';
import { StaticPageType } from '@configs';
import { saveAs } from 'file-saver';
const url = 'api/users';

export enum UserEndpoint {
  AVAILABILITY = 'api/availability/user',
  STATIC_PAGE = 'api/static-pages',
  EDIT_STATIC_PAGE = 'api/static-pages/edit',
  EXPORT_FILE = 'api/availability/export',
}

export const userApi = {
  getUserList: (params?: any) => {
    return axiosClient.get(url, { params });
  },
  getDetail: (id: number) => {
    return axiosClient.get(`${url}/${id}`);
  },
  addNewUser: (body: any) => {
    return axiosClient.post(url, body);
  },
  updateUser: (body: any, id: number) => {
    return axiosClient.put(`${url}/${id}`, body);
  },
  searchUser: (params: any) => {
    return axiosClient.get(`${url}/search`, { params });
  },
  getAvailability: (id: number) => {
    return axiosClient.get(`${UserEndpoint.AVAILABILITY}/${id}`);
  },
  getStaticPage: (type: StaticPageType) => {
    return axiosClient.get(`${UserEndpoint.STATIC_PAGE}/${type}`);
  },
  editStaticPage: (body: { type: StaticPageType; content: string }) => {
    return axiosClient.put(UserEndpoint.EDIT_STATIC_PAGE, body);
  },
  exportCsvFile: (body: any, id: number) => {
    return axiosClient
      .post(`${UserEndpoint.EXPORT_FILE}/${id}`, body, {
        responseType: 'blob', // yêu cầu server trả về dữ liệu dưới dạng blob
        headers: {
          'Content-Type': 'application/json', // thiết lập kiểu dữ liệu gửi đi
          Accept: 'text/csv', // thiết lập kiểu dữ liệu server trả về
        },
      })
      .then((response) => {
        const blob = new Blob([response as any], { type: 'text/csv;charset=utf-8' });
        saveAs(blob);
      });
  },
  exportXlsxFile: (body: any, id: number) => {
    return axiosClient
      .post(`${UserEndpoint.EXPORT_FILE}/${id}`, body, {
        responseType: 'blob', // yêu cầu server trả về dữ liệu dưới dạng blob
        headers: {
          'Content-Type': 'application/json', // thiết lập kiểu dữ liệu gửi đi
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8', // thiết lập kiểu dữ liệu server trả về
        },
      })
      .then((response) => {
        const blob = new Blob([response as any], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        saveAs(blob);
      });
  },
};
