import { DayIcon, NightIcon } from '@assets';
import { CustomCalendar } from '@components';
import { Col, Row, Switch } from 'antd';
import { AvailabilityModule } from './UserViewDetail';
import { isSameDay } from 'date-fns';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface AvailabilityIemProps {
  daysData: AvailabilityModule[];
}

export function AvailabilityIem({ daysData }: AvailabilityIemProps) {
  const data = daysData.length ? daysData.map(({ date }) => new Date(date)) : undefined;

  const { t } = useTranslation('dashboard');

  const [selectedDay, setSelectedDay] = useState<AvailabilityModule | null>(null);

  useEffect(() => {
    if (!daysData.length) return;
    setSelectedDay(daysData[0]);
  }, [daysData]);

  const handleClickDate = (date: Date) => {
    const dateChecked = daysData.find(
      (item) => new Date(item.date).setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0),
    );
    if (!dateChecked) return;
    setSelectedDay(dateChecked);
  };

  const renderTitleClassName = ({ date, view }: any) => {
    if (!data) return '';
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (data.find((dDate) => isSameDay(dDate, date))) {
        return 'react-calendar__tile--active';
      }
    }
    return '';
  };
  return (
    <div>
      <Row align="middle" gutter={40}>
        <Col span={12}>
          <div className="mt-4">
            <CustomCalendar
              tileClassName={renderTitleClassName}
              onClickDay={handleClickDate}
              //   value={data as any}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="mt-4 rounded-[12px] bg-[#37424A] flex p-[24px] max-w-[350px] items-center ">
            <div className="text-[white] p-[24px] border-r-[2px] border-r-[white]">
              <p className="text-[28px] m-0">
                {selectedDay?.date
                  ? Number(new Date(selectedDay.date).getDate()) < 10
                    ? `0${new Date(selectedDay.date).getDate()}`
                    : `${new Date(selectedDay.date).getDate()}`
                  : 'N/A'}
              </p>
            </div>
            <div className="text-[white] p-[24px] flex flex-col justify-center gap-[10px]">
              <div className="flex items-center justify-center gap-[10px] ">
                <Switch disabled checked={selectedDay?.day} />
                <p className="m-0">{t('user_detail.day')}</p>
                <DayIcon />
              </div>
              <div className="flex items-center justify-center gap-[10px]">
                <Switch disabled checked={selectedDay?.night} />
                <p className="m-0">{t('user_detail.night')}</p>
                <NightIcon />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
