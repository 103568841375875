import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { MAIN_THEME_DATA, PATH_FORGOT_PASSWORD, PATH_LOGIN } from '@configs';
import { LogApp } from '@utils';
import { LoginForm, SignUpForm } from '@components';
import { useState } from 'react';
import { debounce } from 'lodash';
import { selectApp, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { authAPI } from '@api';
import i18next from 'i18next';

const schema = yup.object().shape({
  name: yup.string().required(i18next.t('error:company_required')),
  brandName: yup.string().required(i18next.t('error:brand_required')),
  businessType: yup.string().required(i18next.t('error:type_bussiness_required')),
  password: yup.string().required(i18next.t('error:pass_required')),
  confirmPassword: yup
    .string()
    .required(i18next.t('error:cf_pass_required'))
    .oneOf([yup.ref('password'), null], i18next.t('error:cf_not_match')),
  email: yup.string().required(i18next.t('error:work_email_required')),
  phone: yup
    .string()
    .required(i18next.t('error:phone_required'))
    .min(9, i18next.t('error:phone_invalid'))
    .max(13, i18next.t('error:phone_invalid')),
  workPhone: yup.string(),
  workMail: yup.string(),
  fullName: yup.string(),
  people: yup.string().required(i18next.t('error:number_people_required')),
});

export const SignUpModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { themeMode } = useAppSelector(selectApp);
  const [agreePolicy, setAgreePolicy] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleRedirectToLogin = () => {
    navigate(PATH_LOGIN);
  };

  const handleChangeAgreePolicy = debounce(() => {
    setAgreePolicy(!agreePolicy);
  }, 500);

  const handleSignUp = handleSubmit(async (value) => {
    // LogApp('Submit Sign up', value);
    // const body = {
    //   email: value.email,
    //   name: value.name,
    //   password: value.password,
    //   phone: value.phone,
    //   work_phone: value.workPhone,
    //   work_email: value.workMail,
    //   brand_name: value.brandName,
    //   business_type: value.businessType,
    //   people_amount: Number(value.people),
    //   theme_color: MAIN_THEME_DATA.mainColor,
    //   full_name: value.fullName,
    // };
    // try {
    //   dispatch(setLoading(true));
    //   const res: any = await authAPI.register(body);
    //   if (res?.success) {
    //     toast.success('Register successfully!', {
    //       position: 'top-right',
    //       autoClose: 2000,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       theme: themeMode,
    //     });
    //     navigate(PATH_LOGIN);
    //     reset();
    //   } else {
    //     throw res?.data;
    //   }
    // } catch (err: any) {
    //   const error = err?.response?.data;
    //   setError('people', { type: 'custom', message: error?.message });
    //   LogApp('API Sign up', err);
    // } finally {
    //   dispatch(setLoading(false));
    // }
  });

  return (
    <SignUpForm
      register={register}
      redirectToLogin={handleRedirectToLogin}
      errors={errors}
      handleSignUp={handleSignUp}
      agreePolicy={agreePolicy}
      changeAgreePolicy={handleChangeAgreePolicy}
    />
  );
};
