import {
  EditOutlined,
  EyeOutlined,
  SwapOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';

import { Card, Pagination, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { userApi } from '@api';
import { SharedButton, SharedTable, ShareInput } from '@components';
import { INITIAL_PAGINATION, MAIN_THEME_DATA, PATH_USER_MANAGEMENT, StatusUser } from '@configs';
import { UserItem } from '@interfaces';
import { setLoading, setTableLoading, setUserDetail, useAppDispatch } from '@redux';
import moment from 'moment';
import { columns } from './tableCell';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { genderMessageFromApi } from '@utils';

export const UserModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'dashboard']);

  const [params, setParams] = useState<{
    page: number;
    limit: number;
    status?: undefined | StatusUser;
  }>({
    page: 1,
    limit: 10,
    status: undefined,
  });

  const [dataList, setDataList] = useState<UserItem[]>([]);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [searchValue, setSearchValue] = useState<string>('');
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION.limit);
  const valueSearch = useRef<string | undefined>(undefined);

  const fetchUserDataList = async (params?: any) => {
    try {
      dispatch(setTableLoading(true));
      const res = await userApi.getUserList(params);
      const { data } = res;
      const { items, limit, page, total } = data;
      setDataList(items);
      setPagination({ ...INITIAL_PAGINATION, current: page, limit, total });
      dispatch(setTableLoading(false));
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
      if (error) dispatch(setTableLoading(false));
    }
  };

  const fetchUserDetail = async (id: number) => {
    try {
      dispatch(setLoading(true));
      const res = await userApi.getDetail(id);
      dispatch(setUserDetail(res.data));
    } catch (error: any) {
      toast.error(genderMessageFromApi(error?.code));
      if (error) dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchUserDataList(params);
  }, [params]);

  const handleSearchValue = (e: any) => {
    valueSearch.current = e.target.value;
    setSearchValue(e.target.value);
    fetchUserDataList({ ...params, email: e.target.value });
  };

  const renderIcon = (type: string, id: number) => {
    switch (type) {
      case 'edit':
        return (
          <EditOutlined
            className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
            onClick={() => {
              navigate(`${PATH_USER_MANAGEMENT}/edit-user/${id}`);
              fetchUserDetail(Number(id));
            }}
          />
        );

      case 'view':
        return (
          <EyeOutlined
            className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
            onClick={() => {
              navigate(`${PATH_USER_MANAGEMENT}/detail/${id}`);
              fetchUserDetail(Number(id));
            }}
          />
        );

      default:
        return null;
    }
  };

  const genderIconStatus = () => {
    if (params.status === undefined) {
      return <SwapOutlined />;
    }
    if (params.status === StatusUser.ACTIVE) {
      return <ArrowUpOutlined />;
    }
    if (params.status === StatusUser.INACTIVE) {
      return <ArrowDownOutlined />;
    }
  };

  const handleFilterStatus = () => {
    if (params.status === undefined) {
      setParams((prev) => ({ ...prev, status: StatusUser.ACTIVE }));
      return;
    }
    if (params.status === StatusUser.ACTIVE) {
      setParams((prev) => ({ ...prev, status: StatusUser.INACTIVE }));
      return;
    }
    if (params.status === StatusUser.INACTIVE) {
      setParams((prev) => ({ ...prev, status: undefined }));
      return;
    }
  };
  const newDataList = dataList.map((data, index) => {
    return {
      no: index + 1 + (pagination.current * pageSize - pageSize),
      salesforceId: data.salesforceId || 'N/A',
      fullName: data.firstName || data.lastName ? `${data.firstName} ${data.lastName}` : 'N/A',
      email: data.email,
      mobile: data.mobile,
      status: data.status,
      lastUpdate: data.updatedAt,
      // gender: data.gender || 'N/A',
      actions: ['edit', 'inactive', 'view'],
      key: data.id,
    };
  });

  const newColumns = columns.map((col) => {
    if (col.key === 'actions') {
      return {
        ...col,
        render: (_: any, record: any) => {
          return record.actions.map((action: any, index: number) => {
            return <span key={index}>{renderIcon(action, record.key)}</span>;
          });
        },
      };
    }

    if (col.key === 'status') {
      return {
        ...col,
        title: (
          <div
            className="flex cursor-pointer space-x-1 align-middle pt-[13px]"
            onClick={handleFilterStatus}
          >
            <p>{genderIconStatus()}</p>
            <p>Status</p>
          </div>
        ),
        render: (_: any, record: any) => {
          return record.status === 'active' ? (
            <Tag color="green" className="capitalize font-normal text-sm">
              {t('dashboard:user_detail.active')}
            </Tag>
          ) : (
            <Tag color="red" className="capitalize font-normal text-sm">
              {t('dashboard:user_detail.inactive')}
            </Tag>
          );
        },
      };
    }

    // if (col.key === 'gender') {
    //   return {
    //     ...col,
    //     render: (_: any, record: any) => {
    //       return <p className="capitalize font-normal text-sm mt-2">{record.gender}</p>;
    //     },
    //   };
    // }
    if (col.key === 'fullName') {
      return {
        ...col,
        render: (_: any, record: any) => {
          return <p className="capitalize font-normal text-sm">{record.fullName}</p>;
        },
      };
    }
    if (col.key === 'lastUpdate') {
      return {
        ...col,
        render: (_: any, record: any) => {
          return (
            <p className="capitalize font-normal text-sm">
              {moment(record.lastUpdate).format('MM/DD/YYYY, HH:mm:ss')}
            </p>
          );
        },
      };
    }

    return col;
  });

  const handleChangePage = (page: number, pageSize: number) => {
    setPagination({ ...pagination, current: page });
    setPageSize(pageSize);
    fetchUserDataList({ ...params, page, limit: pageSize, email: valueSearch.current });
  };

  return (
    <Card>
      <div className="flex items-center justify-between mb-5">
        <div className="w-1/4">
          <ShareInput
            placeholder={t('dashboard:search_by_email')}
            value={searchValue}
            onChange={handleSearchValue}
          />
        </div>
        <div className="w-1/5">
          <SharedButton
            text={t('dashboard:add_new_user')}
            btnStyle="basic"
            textColor="#fff"
            backgroundColor={MAIN_THEME_DATA.mainColor}
            onClick={() => navigate(`${PATH_USER_MANAGEMENT}/create-new-user`)}
          />
        </div>
      </div>
      <SharedTable columns={newColumns} dataSource={newDataList} />

      <div className="flex justify-end mt-4">
        <Pagination
          current={pagination.current}
          pageSize={pagination.limit}
          total={pagination.total}
          onChange={handleChangePage}
        />
      </div>
    </Card>
  );
};
