import { UserFormPage } from './../pages/userManagement/UserFormPage';
import { IRoute } from '@interfaces';
import { AppLayout, LayoutError } from '@layouts';
import {
  About,
  DashboardPage,
  ForgotPasswordPage,
  LoginPage,
  PageError404,
  ResetPasswordPage,
  SignUpPage,
  TermAndPolicyPage,
  UserDetail,
  UserManagement,
  VerifyEmailPage,
} from '@pages';
import {
  PATH_404,
  PATH_DASHBOARD,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_LOGIN,
  PATH_RESET_PASSWORD,
  PATH_SIGN_UP,
  PATH_USER_MANAGEMENT,
  PATH_VERIFY_EMAIL,
  PATH_CREATE_NEW_USER,
  PATH_EDIT_USER,
  PATH_USER_DETAIL,
  PATH_TERM_AND_POLICY,
  PATH_ABOUT,
} from './navigation';

export const routes: Array<IRoute> = [
  //dashboard
  // { path: PATH_DASHBOARD, page: DashboardPage, layout: AppLayout },
  //loyalty
  //
  { path: PATH_HOME, page: UserManagement, layout: AppLayout },
  { path: PATH_404, page: PageError404, layout: LayoutError },
  { page: PageError404, layout: LayoutError },
  {
    path: PATH_USER_MANAGEMENT,
    page: UserManagement,
    layout: AppLayout,
  },
  {
    path: PATH_CREATE_NEW_USER,
    page: UserFormPage,
    layout: AppLayout,
  },
  {
    path: PATH_EDIT_USER,
    page: UserFormPage,
    layout: AppLayout,
  },
  {
    path: PATH_USER_DETAIL,
    page: UserDetail,
    layout: AppLayout,
  },
  {
    path: PATH_TERM_AND_POLICY,
    page: TermAndPolicyPage,
    layout: AppLayout,
  },
  {
    path: PATH_ABOUT,
    page: About,
    layout: AppLayout,
  },
];

export const normalRoutes = [PATH_HOME];

export const authRoutes: Array<IRoute> = [
  //auth
  { path: PATH_LOGIN, page: LoginPage, auth: true },
  { path: PATH_SIGN_UP, page: SignUpPage, auth: true },
  // { path: PATH_FORGOT_PASSWORD, page: ForgotPasswordPage, auth: true },
  { path: PATH_VERIFY_EMAIL, page: VerifyEmailPage, auth: true },
  // { path: PATH_RESET_PASSWORD, page: ResetPasswordPage, auth: true },
  { page: PageError404, layout: LayoutError },
];
