export const PATH_HOME = '/';
export const PATH_DASHBOARD = '/user-management';
export const PATH_LOYALTY_SETTING = '/loyalty';
export const PATH_SYSTEM_SETTING = '/settings';
export const PATH_MARKETING = '/marketing';
export const PATH_MEMBER = '/members';
//auth
export const PATH_LOGIN = '/login';
export const PATH_SIGN_UP = '/register';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_VERIFY_EMAIL = '/verify-email';
export const PATH_RESET_PASSWORD = '/reset-password';
//User management
export const PATH_USER_MANAGEMENT = '/user-management';
export const PATH_CREATE_NEW_USER = `${PATH_USER_MANAGEMENT}/create-new-user`;
export const PATH_EDIT_USER = `${PATH_USER_MANAGEMENT}/edit-user/:userId`;
export const PATH_USER_DETAIL = `${PATH_USER_MANAGEMENT}/detail/:userId`;

export const PATH_TERM_AND_POLICY = '/term-and-policy';
export const PATH_ABOUT = '/about';

//Loyalty setting
export const PATH_MEMBERSHIP_TIERS = PATH_LOYALTY_SETTING + '/tiers';
export const PATH_CASHBACK_RULES = PATH_LOYALTY_SETTING + '/cashback-rules';
export const PATH_CREATE_CASHBACK_RULES = PATH_CASHBACK_RULES + '/create';
export const PATH_EDIT_CASHBACK_RULES = PATH_CASHBACK_RULES + '/edit/:ruleId';
export const PATH_BIRTHDAY_SPECIAL = PATH_LOYALTY_SETTING + '/birthday-special';
export const PATH_CREATE_MEMBERSHIP_TIERS = PATH_MEMBERSHIP_TIERS + '/create';
export const PATH_EDIT_MEMBERSHIP_TIERS = PATH_MEMBERSHIP_TIERS + '/edit/:id';
//System settings
export const PATH_GENERAL = '/general';
export const PATH_USER_PROFILE = '/profile';
export const PATH_POLICY = '/policy';
export const PATH_BRANCH_GROUP = '/branch-group';
//Marketing
export const PATH_ANNOUNCEMENT = PATH_MARKETING + '/announcement';
export const PATH_EDIT_ANNOUNCEMENT = PATH_ANNOUNCEMENT + '/edit/:id';
export const PATH_CREATE_ANNOUNCEMENT = PATH_ANNOUNCEMENT + '/create';
//MEMBER
export const PATH_MEMBER_PROFILE = PATH_MEMBER + '/member-profile/:memberId';
export const PATH_EDIT_MEMBER_PROFILE = PATH_MEMBER + '/edit/:id';
//Stores
export const PATH_GROUP = PATH_BRANCH_GROUP + '/groups';
export const PATH_STORES = PATH_BRANCH_GROUP + '/stores';
export const PATH_EDIT_STORE = PATH_STORES + '/edit/:id';

//simulator
export const PATH_SIMULATOR = '/simulator';
//error
export const PATH_404 = '/*';
